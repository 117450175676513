<template>
   <div :class="show?'h5main':'main'">
       <mtop />
     <div style="background:#171407;height:142px;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)"></div>
     <img src="../assets/image/1.jpg" alt="NFT" title="NFT">
     <img src="../assets/image/2.png" alt="NFT" title="NFT">
     <img src="../assets/image/3.png" alt="NFT" title="NFT">
     <img src="../assets/image/4.png" alt="NFT" title="NFT">
     <img src="../assets/image/5.png" alt="NFT" title="NFT">
     <img src="../assets/image/6.png" alt="NFT" title="NFT">
     <img src="../assets/image/7.png" alt="NFT" title="NFT">
     <img src="../assets/image/7.png" alt="NFT" title="NFT">
     <img src="../assets/image/8.png" alt="NFT" title="NFT">
     <img src="../assets/image/9.png" alt="NFT" title="NFT">
     <img src="../assets/image/10.png" alt="NFT" title="NFT">
      <mbottom />
   </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";

export default {
   data() {
      return {
         show:false,
      };
   },
   components: {
      mtop,
      mbottom,
   },

   mounted() {
      if (this._isMobile()) {
         this.show = true;
      } else {
         this.show = this.$store.getters.phone;
      }
      //   let divgao=document.querySelector(".right_box1").offsetTop;
      //   this.heightList.push(divgao);
      //   window.addEventListener('scroll',this.handlerScroll,true)
   },
   watch: {
      '$store.getters.phone': function (val) {
      this.show = val
    },
    deep: true,
    immediate: true,
   },
   created() {
   },
   methods: {
      _isMobile() {
         let flag = navigator.userAgent.match(
            /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
         );
         return flag;
      },
      topage(e) {
         this.$router.replace({ path: e });
         this.$store.commit("sid", "");
      },
      // tobuy() {
      //    location.href = "https://opensea.io/collection/cupid-love-metaverse";
      // },
   },
};
</script>
<style lang="scss" scoped>
$t-mf: "Arial";
.main {
   background: #f7f7f7;
   // background: pink;
   min-height: 100vh;
   max-width: 100%;
   min-width: 1200px;
   width: 100%;
   img{
      width:100%;
      margin:0 auto;
   }
   
  
}
.h5main{
   background: #f7f7f7;
   background: #ccc;
   min-height: 100vh;
   max-width: 750px;
   min-width: 640px;
   width: 100%;
}
</style>